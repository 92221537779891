<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <brand :isWhite="true" />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        :class="`d-none d-lg-flex flex-column align-items-center justify-content-between p-0 bg-primary`"
        style="height: 100vh"
      >
        <div
          class="bg-white w-100 text-center d-flex align-items-center justify-content-center"
          style="height: 20%"
        >
          <b-img
            v-if="!isOCB"
            :src="require('@/assets/images/logo/tingedu-logo-horizontal.png')"
            style="max-width: 50%; max-height: 60%"
          ></b-img>
          <div v-else class="d-flex flex-row">
            <b-img
              :src="require('@/assets/images/logo/logo-ocb.svg')"
              style="max-width: 80%; max-height: 80%"
            ></b-img>
            <div
              style="width: 2px; background-color: #e39717"
              class="rounded align-items-stretch mx-2"
            ></div>
            <div
              class="text-left d-flex flex-column justify-content-center align-items-center"
            >
              <!-- <div style="color: #002b70">Ngân hàng TMCP Phương Đông</div> -->
              <div
                style="color: #e39717; font-style: italic"
                class="font-medium-3"
              >
                Niềm tin và thịnh vượng
              </div>
              <!-- <div style="color: #002b70">Thương hiệu Quốc gia 2020 - 2022</div> -->
            </div>
          </div>
        </div>
        <div
          class="w-100 text-center d-flex flex-column align-items-center justify-content-center font-weight-bolder bg-primary"
          style="height: 80%; background-color: #af2031"
        >
          <div v-if="isOCB" style="font-size: 86px" class="text-white">
            OCB SCHOOL
          </div>
          <div
            v-if="isOCB"
            class="text-uppercase"
            style="color: #fee01c; font-size: 30px"
          >
            Giải pháp thu & quản lý học phí tự động
          </div>
          <!-- <div
            v-if="isOCB"
            class="bg-white text-primary mt-3"
            style="
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 40px;
              padding-right: 40px;
              border-radius: 40px;
            "
          >
            Thông tin liên hệ
          </div>
          <div v-if="isOCB" class="text-white mt-1" style="font-size: 18px">
            Ban Dự án Open Banking
          </div>
          <div v-if="isOCB" class="mt-20">
            <span><feather-icon icon="MailIcon" class="text-white" /></span
            ><span class="text-white pl-50 font-weight-normal"
              >openbanking@ocb.com.vn</span
            >
          </div> -->

          <div
            v-if="!isOCB"
            class="text-uppercase text-white"
            style="font-size: 40px"
          >
            Phần mềm quản lý
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-0"
            style="color: #f5c116; font-size: 40px"
          >
            Thu phí tự động
          </div>
          <div v-if="!isOCB" class="text-white pt-3" style="font-size: 16px">
            {{
              provider === "vbill"
                ? "Đáp ứng tốt cho nhiều lĩnh vực thu"
                : "Thiết kế dành riêng cho lĩnh vực"
            }}
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase font-weight-bolder m-1 p-1"
            style="
              background-color: #f5c116;
              color: #002b70;
              font-size: 30px;
              border-color: #002b70;
              border-radius: 15px;
              border-width: 2px;
            "
          >
            {{
              provider === "vbill"
                ? "Điện, nước, phí chung cư, học phí..."
                : "Giáo dục và đào tạo"
            }}
          </div>
          <div
            v-if="!isOCB"
            class="text-uppercase pt-2"
            style="color: #f5c116; font-size: 16px"
          >
            Liên hệ ngay với chúng tôi
          </div>
          <div v-if="!isOCB" class="d-flex flex-row align-items-stretch mt-1">
            <div class="bg-white">
              <b-img
                :src="require('@/assets/images/pages/website_qr.svg')"
              ></b-img>
            </div>
            <div
              class="text-left pl-50 d-flex flex-column justify-content-between"
            >
              <div>
                <span
                  ><feather-icon
                    icon="PhoneIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">0247 1088 968</span>
              </div>
              <div>
                <span
                  ><feather-icon icon="MailIcon" style="color: #f5c116" /></span
                ><span class="text-white pl-50">edu@tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="GlobeIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">www.edu.tingee.vn</span>
              </div>
              <div>
                <span
                  ><feather-icon
                    icon="MapPinIcon"
                    style="color: #f5c116" /></span
                ><span class="text-white pl-50">Hà Nội & TP. Hồ Chí Minh</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/leftimg.svg')">
          </b-img>
        </div>
        <div
          class="text-white flex-row align-items-center justify-content-center"
        >
          <h3 class="text-uppercase text-white font-weight-bold text-center">
            Phần mềm Quản lý thu phí tự động
          </h3>
          <h3
            class="text-uppercase text-white font-weight-bold text-center mt-1"
          >
            dành riêng cho lĩnh vực Giáo dục & Đào tạo
          </h3>
          <div class="d-flex align-items-center justify-content-center mt-4">
            <b-img
              style="width: 50%"
              :src="
                require('@/assets/images/logo/tingedu-logo-white-horizontal.png')
              "
            />
          </div>
        </div> -->
        <!-- <div class="">
          <b-img :src="require('@/assets/images/illustration/rightimg.svg')">
          </b-img>
        </div> -->

        <!-- <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-0"
        >
          <b-img
            fluid
            :src="brandConfig.loginBgImage"
            alt="Login"
            class=""
            style="max-height: 80vh"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Quên mật khẩu? 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Nhập số điện thoại mà bạn đã đăng ký và sử dụng. Sau đó vui lòng
            nhập <strong>mã OTP</strong> gửi đến cho bạn.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPasswordForm">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label="Số điện thoại">
                <template v-slot:label>
                  Số điện thoại
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-phone"
                    placeholder="0868987355"
                    :readonly="status === 1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="status === 1"
                label="Mã OTP (4 số cuối của SĐT gọi đến)"
              >
                <template v-slot:label>
                  Mã OTP
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="pin"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-phone"
                    v-model="pin"
                    :state="errors.length > 0 ? false : null"
                    name="pin"
                    placeholder="6886"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group v-if="status === 1" label="Mật khẩu">
                <template v-slot:label>
                  Mật khẩu
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Mật khẩu"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group v-if="status === 1" label="Xác nhận mật khẩu">
                <template v-slot:label>
                  Xác nhận mật khẩu
                  <span class="text-danger"> (*) </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Xác nhận mật khẩu"
                  vid="passwordConfirm"
                  rules="required|password|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="passwordConfirm"
                      v-model="passwordConfirm"
                      class="form-control-merge"
                      :type="passwordConfirmFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="passwordConfirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordConfirmToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordConfirmVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block>
                {{ status === 0 ? "Gửi mã OTP" : "Đổi mật khẩu" }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Quay về đăng nhập
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Brand from "@/layouts/components/Brand.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required } from "@validations";
// eslint-disable-next-line import/no-cycle
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { togglePasswordConfirmVisibility } from "@/layouts/mixins/forms";
import md5 from "md5";
import { $themeConfig, $brandConfig } from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    Brand,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  data() {
    return {
      status: 0,
      phone: "",
      pin: "",
      password: "",
      passwordConfirm: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    const brandConfig = $brandConfig;
    const isOCB = process.env.VUE_APP_PROVIDER === "ocb";
    const provider = process.env.VUE_APP_PROVIDER;
    return {
      appName,
      appLogoImage,
      brandConfig,
      isOCB,
      provider,
    };
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then((success) => {
        if (success) {
          if (this.status === 0) {
            useJwt
              .forgotPassword({ phone: this.phone })
              .then(() => {
                this.status = 1;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Thông báo",
                    text: "Vui lòng kiểm tra điện thoại để xem 4 số cuối của số điện thoại sẽ gọi đến cho bạn ngay sau đây",
                    icon: "SmartphoneIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status === 422 &&
                  error.response.data &&
                  error.response.data.errors
                ) {
                  this.$refs.forgotPasswordForm.setErrors(
                    error.response.data.errors
                  );
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Thông báo",
                      text: error.response.data.message
                        ? error.response.data.message
                        : "Có lỗi xảy ra, vui lòng thử lại sau",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              });
          } else {
            useJwt
              .resetPasswordWithOTP({
                pin: this.pin,
                password: md5(this.password),
                phone: this.phone,
              })
              .then(() => {
                this.$router.replace({ name: "auth-login" }).then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Thông báo`,
                      icon: "UserIcon",
                      variant: "success",
                      text: `Bạn đã đổi mật khẩu thành công. Vui lòng đăng nhập để tiếp tục sử dụng dịch vụ!`,
                    },
                  });
                });
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status === 422 &&
                  error.response.data &&
                  error.response.data.errors
                ) {
                  this.$refs.forgotPasswordForm.setErrors(
                    error.response.data.errors
                  );
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Thông báo",
                      text: error.response.data.message
                        ? error.response.data.message
                        : "Có lỗi xảy ra, vui lòng thử lại sau",
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
